import React, { useEffect, useState } from 'react';
import { Feature, Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import 'ol/ol.css';
import { createBox } from 'ol/interaction/Draw';
import { LoadingBar, ToolbarButton } from '@grafana/ui';
import { useGeographic } from 'ol/proj';
import { fromExtent } from 'ol/geom/Polygon';
import { config } from '@grafana/runtime';
import { Point } from 'ol/geom';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Circle as CircleStyle } from 'ol/style';
import { DataFrameJSON } from '@grafana/data';
import { fetchAllDataFrames } from 'shared/utils';

// min lon, min lat, max lon, max lat
type BBox = [number | undefined, number | undefined, number | undefined, number | undefined];

interface MapComponentProps {
  bbox: BBox | undefined;
  onBoxChange: (coordinates: BBox) => void;
}

// makes a query to the default timestream db to get sensors that reported coordinates in the past 24 hours
const fetchSensorData = async (): Promise<DataFrameJSON> => {
  // get the first datasource of this type, we should only have one.
  const datasource = config.bootData.settings.datasources['Timestream datasource'];
  const payload = {
    queries: [
      {
        datasource: { uid: datasource.uid },
        // @ts-ignore: jsonData.defaultDatabase seems to actually be in the obj...
        database: datasource.jsonData?.defaultDatabase,
        rawQuery: `
          SELECT
            device_id,
            longitude,
            latitude
          FROM
            $__database.sensors
          WHERE
            time BETWEEN from_milliseconds(${new Date(Date.now() - 86400000).getTime()}) AND from_milliseconds(${new Date().getTime()})
          AND
            latitude IS NOT NULL
          AND
            longitude IS NOT NULL
          GROUP BY
            device_id,
            latitude,
            longitude
          ORDER BY
            device_id
        `
      }
    ]
  };

  return fetchAllDataFrames(payload);
};

const MapComponent: React.FC<MapComponentProps> = ({ bbox, onBoxChange }) => {
  const [drawTool, setDrawTool] = useState<Draw | null>(null);
  const [loading, setLoading] = useState(true);

  // init using geographic coords
  useGeographic();

  // Effect for map instantiation
  useEffect(() => {
    // storage and presentation of bbox
    const bboxSource = new VectorSource();
    const bboxLayer = new VectorLayer({source: bboxSource});

    // init map with raster basemap and bbox layer already loaded
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          preload: Infinity,
          source: new XYZ({
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attributions:
              'Only sensors from the last 24 hours are shown. Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
          }),
        }),
        bboxLayer
      ],
      view: new View({
        center: [0, 0],
        zoom: 0,
        maxZoom: 17
      }),
    });

    // zoom to existing box, if any
    if (bbox && bbox.every(item => item !== undefined)) {
      // add bbox shape to the layer
      bboxSource.addFeature(new Feature(fromExtent(bbox)))
      // zoom to extent
      map.getView().fit(bbox, { padding: [30, 30, 30, 30] });
    }

    // draw tool for a new box
    const draw = new Draw({
      source: bboxSource, // not required, but this automatically adds features to this layer
      type: 'Circle', // yo wtf the docs legit say to use circle to draw a box...
      geometryFunction: createBox(),
    });
    draw.setActive(false) // not active at the start
    // clear any previous geoms when you start a sketch
    draw.on('drawstart', () => bboxSource.clear())
    // when you finish a sketch, emit the bbox
    draw.on('drawend', (evt) => {
      onBoxChange(evt.feature.getGeometry()?.getExtent() as BBox)
      draw.setActive(false)
    })
    // add to map
    map.addInteraction(draw)

    // Set the map and sources in the state
    setDrawTool(draw);

    // Fetch and plot sensor locations from the past 24 hours
    fetchSensorData().then(({ schema, data }) => {
      const deviceIdIndex = (schema?.fields || []).findIndex(field => field.name === 'device_id');
      const longitudeIndex = (schema?.fields || []).findIndex(field => field.name === 'longitude');
      const latitudeIndex = (schema?.fields || []).findIndex(field => field.name === 'latitude');
      const dataValues = data?.values || []

      if (deviceIdIndex !== -1 && longitudeIndex !== -1 && latitudeIndex !== -1) {
        const sensorSource = new VectorSource();
        const sensorLayer = new VectorLayer({
          source: sensorSource,
          style: feature => new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({ color: '#c4162a' }),
            }),
            text: new Text({
              text: feature.get('device_id'),
              offsetY: -15,
              font: '14px sans-serif',
              fill: new Fill({ color: '#000' }),
              stroke: new Stroke({ color: '#fff', width: 2 })
            })
          })
        });
        map.addLayer(sensorLayer);

        dataValues[longitudeIndex].forEach((longitude, index) => {
          const latitude = dataValues[latitudeIndex][index] as number;
          const deviceId = dataValues[deviceIdIndex][index];
          // if the lat or lon are invalid, skip this one
          if (latitude < -90 || latitude > 90 || longitude  as number < -180 || longitude as number > 180) {
            console.log(`${deviceId} coordinates invalid: ${latitude} lat, ${longitude} lon.`)
            return
          }
          const pointFeature = new Feature(new Point([Number(longitude), Number(latitude)]));
          pointFeature.set('device_id', deviceId);
          sensorSource.addFeature(pointFeature);
        });

        // if there's no existing bbox, but there IS sensor data, zoom to sensor data extent
        if (bboxSource.isEmpty() && !sensorSource.isEmpty()) {
          map.getView().fit(sensorSource.getExtent(), { padding: [30, 30, 30, 30] });
        }
      }
      setLoading(false)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div>
      {loading && <div style={{marginTop: '-1px'}}><LoadingBar width={500} /></div>}
      <div style={{ height: '315px', width: '500px' }} id="map" className="map-container" />
      <ToolbarButton icon="capture" tooltip="Select a site box" iconSize='xl' variant='primary'
        onClick={() => drawTool?.setActive(true)} style={{top: '-40px', left: '10px'}}
      />
    </div>
  );
}

export default MapComponent;
