import { PluginPage, config, getBackendSrv } from '@grafana/runtime';
import { Button, InteractiveTable, LoadingPlaceholder, Modal, Select, Stack, useStyles2 } from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import { useAsync } from "react-use";
import { BASE_URLS } from 'shared/constants';
import { Site } from 'shared/types';
import { css } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { downloadBlob, fetchAllDataFrames } from 'shared/utils';

type SiteDateObj = Site & {startObj: Date, endObj: Date} // let ts know these will 100% not be null
type SensorUsage = {days: string[], sensorIds: string[][]}
type SiteWithBilling = SiteDateObj & SensorUsage

const backendSrv = getBackendSrv();

function getMonthsBetweenDates(startDate: Date, endDate: Date) {
  const result = [];
  const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // Start at the first day of the start month

  while (currentDate <= endDate) {
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    
    result.push({
      label: `${month} ${year}`,
      value: new Date(currentDate)
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return result.sort((a, b) => b.value.getTime() - a.value.getTime());
}

async function getSiteSensorDays(site: SiteDateObj, monthStart: Date, monthEnd: Date): Promise<SensorUsage> {
  // get the first datasource of this type, we should only have one.
  const datasource = Object.values(config.bootData.settings.datasources).find(ds => ds.type === 'grafana-timestream-datasource')
  if (datasource?.uid === undefined) {
    throw 'Datasource for this site not found; data may be inaccurate.'
  }
  const bbox = `${site.min_lon}, ${site.min_lat}, ${site.max_lon}, ${site.max_lat}`
  const query = `
    SELECT
      format_datetime(time, 'yyyy-MM-dd') as "date",
      array_distinct(array_agg(device_id)) as "sensor_ids"
    FROM
      $__database.sensors
    WHERE
      $__timeFilter
      AND
      cast(longitude as double) between element_at(ARRAY[${bbox}], 1) and element_at(ARRAY[${bbox}], 3)
      AND
      cast(latitude as double) between element_at(ARRAY[${bbox}], 2) and element_at(ARRAY[${bbox}], 4)
    GROUP BY
      format_datetime(time, 'yyyy-MM-dd'),
      date(time)
    ORDER BY
      date(time)
  `
  const payload = {
    // start counting from the later of either: the start of the month, or the site's start date.
    // so if you installed the site in april, but this billing period is june, we start counting june 1.
    from: Math.max(monthStart.getTime(), site.startObj.getTime()).toString(),
    // stop at the earlier of either: the end of the month, or the site's end date.
    to: Math.min(monthEnd.getTime(), site.endObj.getTime()).toString(),
    queries: [
      {
        datasource: { uid: datasource.uid },
        // @ts-ignore: jsonData.defaultDatabase seems to actually be in the obj...
        database: datasource.jsonData?.defaultDatabase,
        rawQuery: query
      }
    ]
  }

  const response = await fetchAllDataFrames(payload)
  const respData = response.data || {values: []}

  return { // arrays where both the dateObj and active sensors on that date share the same index in each array
    days: respData.values[0] as string[],
    sensorIds: respData.values[1].map((ids) => JSON.parse(ids as string).sort())
  }
}

export function Billing() {
  const s = useStyles2(getStyles);

  const pageNav = {
    text: `Billing - ${config.bootData.user.orgName}`,
    subTitle: 'View sensor usage for the selected month. Dates are based on your browser time.'
  }

  // manage the billing table
  const [monthFilter, setMonthFilter] = useState<SelectableValue>(getMonthsBetweenDates(new Date(), new Date())[0]); // default to current month
  const [monthOptions, setMonthOptions] = useState<SelectableValue[]>([]);
  const [loadingSensors, setLoadingSensors] = useState(true);
  // detail modal is managed using the active site(s)
  const [activeSites, setActiveSites] = useState<SiteWithBilling[]>([]);

  // fetch all sites
  const { loading: loadingSites, value: allSites } = useAsync<() => Promise<SiteDateObj[]>>(async () => {
    const response = await backendSrv.get(`${BASE_URLS.API}/sites`);
    const sites: Site[] = response?.data?.sites || [];
    return sites.map(site => ({...site, startObj: new Date(site.start_date), endObj: new Date(site.end_date)}))
  })

  // compute month options from the earliest site we can find
  useEffect(() => {
    const startDateObjArray = (allSites || []).map(site => site.startObj.getTime());
    const minDate = new Date(Math.min.apply(null, startDateObjArray));
    const _monthOptions = getMonthsBetweenDates(minDate, new Date())
    setMonthOptions(_monthOptions);
  }, [allSites])

  // init cols and data for the table
  const cols = React.useMemo(() => {
    return [
      {id: 'name', header: 'Site name', sortType: 'string',
        cell: ({row: {original: site}}: {row: {original: SiteWithBilling}}) => (
          <a className={s.clickableName} onClick={() => setActiveSites([site])}>{site.name}</a>
      )},
      {id: 'startObj', header: 'Start date', sortType: 'datetime',
        cell: ({row: {original: site}}: {row: {original: SiteWithBilling}}) => (
          site.startObj?.toLocaleDateString()
      )},
      {id: 'endObj', header: 'End date', sortType: 'datetime',
        cell: ({row: {original: site}}: {row: {original: SiteWithBilling}}) => (
          site.endObj?.toLocaleDateString()
      )},
      {id: 'activeDays', header: 'Active days (selected month)',
        cell: ({row: {original: site}}: {row: {original: SiteWithBilling}}) => (
          site.days.length // just a raw count of the number of days this site had sensors reporting data
      )},
      {id: 'sensorDays', header: 'Sensor days (selected month)',
        cell: ({row: {original: site}}: {row: {original: SiteWithBilling}}) => (
          // the sum of the daily count of sensors reporting each month. 1 sensor for 30 days is 30 sensor days.
          site.sensorIds.flat().length
      )},
    ]
  }, [s.clickableName])
  const [data, setData] = useState<SiteWithBilling[]>([])

  // update data whenever the month filter is changed
  useEffect(() => {
    const monthStart = monthFilter?.value;
    const monthEnd = new Date(monthStart);
    monthEnd.setMonth(monthEnd.getMonth() + 1);
    monthEnd.setDate(0); // Last day of the month

    // only sites that fall somewhere within this month
    const currentMonthSites = (allSites || []).filter(site => site.startObj <= monthEnd && site.endObj >= monthStart);
    
    // async function to wait for all timestream queries to resolve before setting the data.
    const fetchData = async () => {
      setLoadingSensors(true)
      // query timestream for sensor data from each site for the current month.
      const sitesWithSensorUsage = await Promise.all(
        currentMonthSites.map(async site => {
          const sensorUsage = await getSiteSensorDays(site, monthStart, monthEnd);
          return { ...site, ...sensorUsage };
        })
      );
      setData(sitesWithSensorUsage);
      setLoadingSensors(false)
    };

    fetchData();
  }, [allSites, monthFilter]);

  const downloadBillingCsv = () => {
    const rows: string[] = []
    activeSites.forEach(site => {
      rows.push(site.name)
      if (site.days.length) {
        rows.push('Date,Sensor count,Sensor IDs')
        rows.push(...site.days.map((day, ndx) => `${day},${site.sensorIds[ndx].length},${site.sensorIds[ndx].join('; ')}`))
      } else {
        rows.push('No sensor data was found for this month.')
      }
      rows.push('')
    })
    const csvString = rows.join('\n')
    downloadBlob(csvString, `${monthFilter.label} usage report.csv`, 'text/csv;charset=utf-8;')
  }

  return (
    <PluginPage actions={<Button onClick={() => setActiveSites(data)} disabled={loadingSites || loadingSensors}>Month details</Button>} pageNav={pageNav}>
      <Modal
        title={`${activeSites.length > 1 ? 'All sites' : activeSites[0]?.name} (${monthFilter.label})`}
        isOpen={activeSites.length > 0}
        onDismiss={() => setActiveSites([])}
        className={s.modalSmall}
      >
        <Stack direction='column'>
          {activeSites.map(site =>
            <div key={site.id}>
              <h4>{site.name}</h4>
              {site.days.length ? (
                <table className={s.tablePadding}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Sensors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {site.days.map((day, index) => 
                      <tr key={site.id + day}>
                        <td>{day}</td>
                        <td>{site.sensorIds[index].join(', ')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                'No sensor data was found for this month.'
              )}
              <hr></hr>
            </div>
          )}
        </Stack>
        <Modal.ButtonRow>
          <Button icon="file-download" onClick={downloadBillingCsv} variant='secondary'>Download</Button>
          <Button variant="secondary" fill="outline" onClick={() => setActiveSites([])}>Close</Button>
        </Modal.ButtonRow>
      </Modal>
      <div>
        <Stack gap={2} alignItems="center">
          <Select
            aria-label='Month'
            value={monthFilter}
            onChange={(event) => setMonthFilter(event)}
            options={monthOptions}
          />
        </Stack>
        {(loadingSites || loadingSensors) ? (
          <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
            <LoadingPlaceholder text="Computing billing data..." />
          </div>
        ) : (
          <InteractiveTable columns={cols} data={data} getRowId={(site: Site) => site.id} pageSize={50} className={s.marginTop} />
        )}
      </div>
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  clickableName: css({
    ':hover': {
      'text-decoration': 'underline',
      color: theme.colors.primary.text
    }
  }),
  modalSmall: css`
    width: fit-content;
  `,
  tablePadding: css({
    'th, td': {
      padding: '2px 8px 2px 8px'
    },
    'th': {
      textDecoration: 'underline'
    },
    'tbody>tr:nth-child(odd)': {
      backgroundColor: 'rgb(61 113 217 / 10%)'
    }
  })
});
